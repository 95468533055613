// Modules
import { useEffect, useState } from 'react';

// Components
import LoadingScreen from './screens/LoadingScreen';

// Styles
import './App.css';

// Custom
import { ChatScreen } from 'previta-videocall';
import AlertModal from './components/shared/AlertModal';
import { PostMessageType } from './types';

const App = () => {
  const [disconnect, setDisconnect] = useState(true);
  const [showModalError, setShowModalError] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [show, setShow] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [identity, setIdentity] = useState('');
  const [email, setEmail] = useState('');
  const [key, setKey] = useState('');
  const [token, setToken] = useState('');
  const [isProd, setIsProd] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getParams();
    setTimeout(() => {
      setIsLoading(false);
      setShow(true);
      setDisconnect(false);
    }, 1000);
  }, []);

  useEffect(() => {
    if (!identity || !email || !key || !token)
      setShowModalError(true)
  }, [identity, email, key, token]);

  const handleClose = () => {
    setDisconnect(true);
    setShowModal(true);
  };

  const getParams = () => {
    const userInformation = { email: '', name: '', key: '', token: '', isProd: null};
    const queryParams = new URLSearchParams(window.location.search)
    // @ts-ignore
    for (const [key, value] of queryParams) userInformation[key] = value;
    setEmail(userInformation.email);
    setIdentity(userInformation.name);
    setKey(userInformation.key);
    setToken(userInformation.token);
    setIsProd(userInformation.isProd ? true : false);
  };

  if (isLoading) {
    return (<LoadingScreen open={isLoading} />);
  } else if (identity && email && key && token) {
    if (!disconnect) {
      if (showChat) {
        return (
          <ChatScreen
            apiKey={key}
            apiToken={token}
            source='Membresía Previta'
            identity={identity}
            email={email}
            onLeave={handleClose}
            logo=''
            setDisconnect={setDisconnect}
            isProd={isProd}
          />
        );
      } else {
        return (
          <AlertModal
            closeChat={() => setShow(false)}
            showModal={show}
            title='Asistente médico'
            message='¿Está seguro que desea iniciar chat?'
            onConfirm={() => {
              setShowChat(true);
              setShow(false);
            }}
            type={PostMessageType.USER_CLOSED}
          />
        );
      }
    } else {
      return (
        <AlertModal
          closeChat={() => setShowModal(false)}
          showModal={showModal}
          message='Atención terminada'
          buttonName='Aceptar'
          buttonColor='#1C84C6'
          type={PostMessageType.SUCCESS}
        />
      );
    }
  }
  else {
    return (
      <AlertModal
        closeChat={() => setShowModalError(false)}
        showModal={showModalError}
        title='ERROR'
        message='No es posible conectarse al chat, por favor intente más tarde'
        type={PostMessageType.BAD_REQUEST}
        buttonName='Aceptar'
      />
    );
  }
}

export default App;
