import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";
import PrevitaLogo from './media/PrevitaLogo.jpg';

type Props = {
  closeChat: () => void;
  showModal: boolean;
  buttonName?: string;
  buttonColor?: string;
  message: string;
  title?: string;
  type?: string;
  onConfirm?: () => void;
};

export default function AlertModal({
  closeChat,
  showModal,
  buttonName,
  buttonColor,
  message,
  title,
  onConfirm,
  type,
}: Props) {
  if (showModal) {
    return (
      <Dialog
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ fontWeight: "bolder" }}>
          <Box style={{ display: "flex", justifyContent: "flex-end" }} >
            <img src={PrevitaLogo} style={{ height: 30 }} alt="PrevitaLogo" />
          </Box>
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{ fontWeight: "normal" }}>
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            id="close-chat"
            style={{ fontWeight: "bold", backgroundColor: buttonColor || "#ED5565", color: "#FFFFFF" }}
            onClick={(e) => {
              e.preventDefault();
              if (window && window.parent && type) {
                window.parent.postMessage(
                  {
                    type: type,
                    message: message,
                  },
                  "*"
                );
              }
              closeChat();
            }}
          >
            {buttonName || 'Cancelar'}
          </Button>
          {onConfirm && (
            <Button
              id="continue-chat"
              style={{ fontWeight: "bold", backgroundColor: "#1C84C6", color: "#FFFFFF" }}
              onClick={(e) => {
                onConfirm();
              }}
            >
              Continuar
            </Button>
          )}
        </DialogActions>
      </Dialog >
    );
  } else {
    return <></>;
  }
}
