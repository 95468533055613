import CircularProgress from '@material-ui/core/CircularProgress';
import { Box, Typography } from '@material-ui/core';
import PrevitaLogo from '../components/shared/media/PrevitaLogo.jpg';

type Props = {
  open: boolean
}

const LoadingScreen = ({ open }: Props) => {
  if (open) {
    return (
      <Box id='main-container' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', flexDirection: 'column' }}>
        <img src={PrevitaLogo} style={{ height: 150 }} alt="PrevitaLogo" />
        <br />
        <Box>
          <CircularProgress color='secondary' />
        </Box>
        <Typography style={{ color: 'grey' }} variant='overline'>
          Por favor espere un momento
        </Typography>
        <Typography variant='h5'>
          Estamos estableciendo la conexión
        </Typography>
      </Box >
    );
  } else {
    return <></>
  }
}

export default LoadingScreen;
